import React from 'react';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider, touchRippleClasses } from "@mui/material";
import { responsiveFontSizes, createTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { FaHome, FaPizzaSlice, FaHamburger, FaMapMarkedAlt, FaEuroSign, FaUserTie, FaHospitalUser } from "react-icons/fa";
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import swal from 'sweetalert';
import noImage from './../images/noImage.png';
import idle from './../images/icons/idle_on.png';
import registered from './../images/icons/registered_on.png';
import active from './../images/icons/active_on.png';
import discontinued from './../images/icons/discontinued_on.png';

import level_employee from './../images/icons/employee.png';
import level_coordinator from './../images/icons/coordinator.png';
import level_owner from './../images/icons/owner.png';

import employee_month from './../images/icons/employee_month.png';
import employee_month2 from './../images/icons/employee_month2.png';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PasswordIcon from '@mui/icons-material/Password';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PinIcon from '@mui/icons-material/Pin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import BackOfficeConfig from './../Config';
import Pin from '@mui/icons-material/Pin';

import Client from './Client';
import Config from './ClientConfig';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

import TokenIcon from '@mui/icons-material/Token';
import UpdateIcon from '@mui/icons-material/Update';
import DropDown from './DropDown';
import { post } from 'jquery';


let theme = createTheme();

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#273442",
    color: theme.palette.common.white,
    paddingLeft: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingLeft: "4px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const LocationSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

class LocationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      client: new Client ( "none" ),     
      edit: false,
      row: {},
      somethingHasChanged: false
      //addNewProduct: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.changeType = this.changeType.bind(this);
    this.pushData = this.pushData.bind(this);
  }

  componentDidMount ( ) {
    if ( this.props ) {
      if ( this.props.dataRow ) {
        this.setState ( { row: this.props.dataRow } );
      }
    }
  }

  handleEditClick ( ) {
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
  }

  changeType ( e ) {
    let uRow = this.state.row;
    uRow.level = parseInt ( e );
    this.setState ( { row: uRow } );
    this.setState ( { somethingHasChanged: true } );
  }

  pushData ( e ) {
    if ( this.state.edit && this.state.somethingHasChanged ) {      
      let row = this.state.row;
      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONEMPLOYEES_UPDATE_LEVEL, row );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uRow = this.state.row;
          uRow.iref = rdata.iref;
          this.setState ( { row: uRow } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified employee ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            //this.props.employeeUpdated ( );
            this.setState ( { somethingHasChanged: false } );            
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update employee failed !", "error" );
        }
      }
    }
  }

  handleSave ( ) {
    this.pushData ( );
  }


  render() {
      let name = "";
      let status = "";
      let locationType = "";
      let relation = "";
      let add_street = "";
      let add_number = "";
      let add_floor = "";
      let add_city = "";
      let add_county = "";
      let add_country = "";
      let locationImage = noImage;
      let companyName = "";
      let companyUID = "";
      let level = 0;
      let levelText = "Employee";

      let row = [];
      if ( this.props.dataRow !== null && this.props.dataRow != undefined ) {
        row = this.props.dataRow;
      }

      if ( this.state.row ) {
        let row = this.state.row;

        if ( row.level !== undefined ) {
          level = row.level;
          switch ( row.level ) {
            case 0:        
              levelText = "Employee";
              break;
            case 1:
              levelText = "Coordinator";
              break;
            case 2:        
              levelText = "Owner";
              break;
          }
        }

        if ( row.location ) {
          name = row.location.name;
          status = row.location.status;
          locationType = row.location.locationType;
          relation = row.location.relation;
          
          if ( row.location.address ) {
            add_street = row.location.address.street;
            add_number = row.location.address.number;
            add_floor = row.location.address.floor;
            if ( row.location.address.city ) {
              add_city = row.location.address.city.name;
              if ( row.location.address.city.county ) {
                add_county = row.location.address.city.county.name;
                if ( row.location.address.city.county.country ) add_country = row.location.address.city.county.country.name;
              }
            }
          }
  
          let locationImagesObj = row.location.locationImage_location;
          let locationImages = Object.entries ( locationImagesObj );
          let fImage = false;
          locationImages.map ( (image) => {
            if ( fImage === false ) {
              locationImage = image[1].image;
              fImage = true;
            } else {
              if ( image[1].isMain === true ) locationImage = image[1].image;
            }          
          });
  
          if ( row.location.company ) {
            companyName = row.location.company.name;
            companyUID = row.location.company.cuid;
          }
  
        }
      }

      let showSave = false;
      if ( this.state.edit && this.state.somethingHasChanged )
        showSave = true;
    
    return (
      <StyledTableRow key={row.iref} sx={{padding: "1px"}}>

        <StyledTableCell align="left" sx={{padding: "1px"}}>        
          <Box
            component="img"
            sx={{
              height: 48,
              width: 48,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
              border: '1px dashed grey'
            }}
            alt={name}
            src={locationImage}
          />
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {name}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>         
          {companyName} ({companyUID})
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {add_street}, n. {add_number}, et. {add_floor}, {add_city}, {add_county}, {add_country}
        </StyledTableCell>

        <StyledTableCell component="td" scope="row" sx={{padding: "1px"}}>
          {this.state.edit ?
            <DropDown
              name="Employee Type"
              value={level}
              doptions={BackOfficeConfig.EMPLOYEE_TYPES}
              hasIcons={false} 
              minWidth={120}
              onChangeFunction={this.changeType}
              size="small"
            />
            : levelText
          }
          
        </StyledTableCell>

        <StyledTableCell align="right" sx={{padding: "1px"}}>
          <IconButton aria-label="settings" onClick={this.handleEditClick}>
            <EditIcon sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }} />
          </IconButton>
          {this.state.edit && showSave ?
            <IconButton aria-label="settings" onClick={this.handleSave}>
              <SaveIcon sx={{ color: "#4287f5" }} />
            </IconButton>
          : ""}
        </StyledTableCell>

      </StyledTableRow>
    );
  }
}


class POS extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      expanded: false,
      edit: false,
      somethingHasChanged: false,
      saveButtonVisible: false,
      //combo_mainImage: null,
      pos: {
        user: {
          user_type: 0,
          user_status: 0,
          person: {
            firstName: "",
            lastName: "",
          }
        },
        updateKey: "",
        location: {
          name: "",
          relation: 0,
          status: "",
          address: {
            name: "",
            city: {
              name: "",
              county: {
                name: "",
                country: {
                  name: ""
                }
              }
            }
          }
        },
        sn: "",
        name: "",
        description: "",
        status: 0
      },
      personsSearch: [],      
      locations: [],
      locationNames: [],
      locationsIref: [],
      cardColor: "#FFFFFF",

      nameValid: false,
      snValid: false,
      descriptionValid: false,
      addNewPOS: false

    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.getElementByName = this.getElementByName.bind(this);
    this.personChange = this.personChange.bind(this);
    this.personSearch = this.personSearch.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.pushData = this.pushData.bind(this);
    this.changeLocation = this.changeLocation.bind(this);    
    this.getLocations = this.getLocations.bind(this);    
  }


  componentDidMount() {
    if ( Object.entries(this.props.pos).length === 0 ) {
      this.handleEditClick ( );
    } else {
      if ( this.props.pos !== null ) {
        let uPOS = this.props.pos;        
        
        if ( uPOS.name )
          if ( uPOS.name.length > 3 ) this.setState ( { nameValid: true } );
        if ( uPOS.sn )
          if ( uPOS.sn.length > 3 ) this.setState ( { snValid: true } );
        if ( uPOS.description )
          if ( uPOS.description.length > 3 ) this.setState ( { descriptionValid: true } );

        if ( uPOS.iref === "newPOS" ) {
          this.setState ( { edit: true } );
          if ( this.props.location )
            uPOS [ "location" ] = { iref: this.props.location };
        }

        this.setState ( { pos: uPOS } ); 
      }
      if ( this.props.cardColor !== null ) {
        this.setState ( { cardColor: this.props.cardColor } );
      }
    }
  }

  componentDidUpdate ( oldProps ) {
    if ( this.props != oldProps ) {
      if ( this.props.pos !== null ) {
        let uPOS = this.props.pos;        
        this.setState ( { pos: uPOS } );
      }
    }
  }

  handleExpandClick ( ) {
    this.setState({ expanded: !this.state.expanded });
  }

  getLocations ( ) {
    let queryData = {
      "orderBy_county": true,
      "orderBy_name": true
    }
    let data = this.state.client.getData ( Config.API_URL_LOCATIONS, queryData );
    if ( data ) {
      if ( data.locations ) {
        let locations = [];
        data.locations.map((location) => {
          let newLocation = "[" + location.iref + "][" + location.address.city.county.name + ", " + location.address.city.name + "] " + location.name;
          locations.push ( newLocation );
        });
        this.setState ( { locations: locations } );
      }
    }
    //this.setState ( { employees: data.employees });
  }

  handleEditClick ( ) {
    //let editMode = this.state.edit;
    let cEdit = !this.state.edit;
    this.setState({ edit: !this.state.edit });
    /* if ( this.state.expanded === false && cEdit === true )
      this.state.expanded = true;
    if ( this.state.expanded === true && cEdit === false )
      this.state.expanded = false; */
    this.setState({ saveButtonVisible: cEdit });
    if ( cEdit ) this.getLocations ( );
  }

  getElementByName ( elements, name ) {    
    let e = {
      "iref" : null,
      "name" : "X",
      "image" : "X",
      "image_off" : "X"
    };
    if ( elements !== null && elements !== undefined ) {
      for ( var i = 0; i < elements.length; i++ ) {
        let el = elements [ i ];
        if ( el.length === 2 ) {
          if ( el [ 1 ].name === name ) e = el [ 1 ];
        } else {
          if ( el [ 0 ].name === name ) e = el [ 0 ];
        }        
      }
    }
    return e;    
  }

  personSearch ( e ) {
    //console.log ( e.target.value );
    if ( e.target.value.length > 3 ) {
      let queryData = {
        "nameLike" : e.target.value,
        "notLinked_employee": true,
        "notLinked_user": true
      };
      let data = this.state.client.filterPersons ( queryData );
      let newpersons = [];
      let persons = [];
      Object.keys(data.persons).forEach(key => {
        let fData = data.persons [ key ];
        let lineText = fData.iref + " - " + fData.firstName + " " + fData.lastName + " (" + fData.email + ", " + fData.phone + ")";
        let newObj = { key: fData.iref, label: lineText };
        newpersons.push ( newObj );
        persons.push ( fData );
        //counties [ fData.iref ] = fData.name;      
      });

      this.setState ( { personsSearch: newpersons });
    }
  }

  personChange ( e ) {
    let text = e.target.textContent;
    let repIref = text.substring ( 0, text.indexOf(" - ") );
    let queryData = { };
    let data = this.state.client.getData ( Config.API_URL_PERSON + repIref, queryData );
    let uEmployee = this.state.employee;
    uEmployee.person = data;
    this.setState ( { employee: uEmployee } );    
    this.setState ( { somethingHasChanged: true } );
  }

  changeValue ( e ) {
    let id = null;
    if ( e.target.id !== null ) id = e.target.id;
    let value = null;
    if  ( e.target.value !== null ) value = e.target.value;
    if ( id !== null) {
      let uPOS = this.state.pos;
      switch ( id ) {
        case "name":
          uPOS.name = value;
          if ( value.length > 3 ) this.setState ( { nameValid: true } );
          else this.setState ( { nameValid: false } );
          break;
        case "sn":
          uPOS.sn = value;
          if ( value.length > 3 ) this.setState ( { snValid: true } );
          else this.setState ( { snValid: false } );
          break;  
        case "description":
          uPOS.description = value;
          if ( value.length > 3 ) this.setState ( { descriptionValid: true } );
          else this.setState ( { descriptionValid: false } );
          break;        
      }
      this.setState ( { pos: uPOS } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  pushData ( e ) {
    if ( this.state.edit && this.state.somethingHasChanged && this.state.nameValid && this.state.snValid && this.state.descriptionValid ) {
      let pos = this.state.pos;

      let rdata = this.state.client.pushData ( Config.API_URL_LOCATIONS_POS_ADD, pos );
      if ( rdata ) {
        if ( rdata.status == "OK" ) {
          let uPOS = this.state.pos;
          uPOS.iref = rdata.iref;
          this.setState ( { post: uPOS } );
          this.handleEditClick ( );
          swal ( "Success", "Successfully added/modified POS ! (" + rdata.iref + ")", "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            this.props.posUpdated ( );
            this.setState ( { somethingHasChanged: false } );            
          });        
        } else {
          if ( rdata.message ) this.setState ( { errorMessage: rdata.message } );
          swal ( "Error", "Add/Update POS failed !", "error" );
        }
      }
    }
  }

  changeLocation ( e ) {
    let uLocationNames = this.state.locationNames;
    let value = e.target.value;
    let newValue = typeof value === 'string' ? value.split(',') : value;
    

    let locationsIrefs = [];
    newValue.map((v) => {
      let c1 = v.indexOf ( "[" ) + 1;
      let c2 = v.indexOf ( "]" );
      let iref = v.substring ( c1, c2 );
      locationsIrefs.push ( iref );
    });

    let uEmployee = this.state.employee;
    uEmployee.locations = locationsIrefs;
    this.setState ( { locationNames: newValue } );
    this.setState ( { employee: uEmployee } );
    this.setState ( { somethingHasChanged: true } );
  }

  changeStatus ( e ) {
    let uPOS = this.state.pos;
    if ( e !== undefined ) {
      uPOS.status = parseInt ( e );
      this.setState ( { pos: uPOS } );
      this.setState ( { somethingHasChanged: true } );
    }
  }

  render() {

    let iref = "";
    let sn = "";
    let name = "";
    let description = "";
    let status = 0;
    let user = {};
    let person = {};
    let location = {};
    let location_address = {};
    let statusIcon = noImage;
    let statusText = "";
    let location_details = "";
    let username = "";
    let password = "";
    let activationToken = "";
    let modifiedOn = "";

    let pos = {};
    
    if ( this.state.pos ) {
      pos = this.state.pos;

      if ( pos.iref ) iref = pos.iref;
      if ( pos.sn ) sn = pos.sn;
      if ( pos.name ) name = pos.name;
      if ( pos.description ) description = pos.description;
      if ( pos.status !== undefined ) status = pos.status;
      if ( pos.modifiedOn ) modifiedOn = pos.modifiedOn;
      if ( pos.aKey ) activationToken = pos.aKey;

      statusIcon = BackOfficeConfig.USER_STATUSES_WITH_ICONS [ status ].image;
      statusText = BackOfficeConfig.USER_STATUSES_WITH_ICONS [ status ].name;

      if ( pos.user ) {
        user = pos.user;
        if ( user.person ) person = user.person;
      }

      if ( pos.location ) {
        location = pos.location;
        if ( location.address ) location_address = location.address;
      }
    }

    

    let personsSearch = [ ];
    if ( this.state.personsSearch ) personsSearch = this.state.personsSearch;

    /* let hasPerson = false;    
    if ( this.state.employee.person && 'iref' in this.state.employee.person ) hasPerson = true; */

    let saveButtonVisible = false;
    if ( this.state.edit && this.state.somethingHasChanged && this.state.nameValid &&
        this.state.snValid && this.state.descriptionValid )
        saveButtonVisible = true;

    let locations = [ ];
    if ( this.state.locations ) locations = this.state.locations;

    let locationNames = [];
    if ( this.state.locationNames.length > 0 ) locationNames = this.state.locationNames;

    let userMargin = 0;
    let cardMargin = 0;
    if ( this.state.edit ) {
      userMargin = 2;
      cardMargin = 3;
    }

    let cardColor = "#FFFFFF";
    if ( this.state.cardColor ) cardColor = this.state.cardColor;

    
    

    return (
      /* , display: 'flex' */
    <Card sx={{ borderRadius: '16px', m: 1, backgroundColor: cardColor }}>

      <Box sx={{ display: 'block', flexDirection: 'row', width: "100%" }}>
        <CardHeader
          avatar={
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Avatar alt={statusText} src={statusIcon} title={statusText}>
                </Avatar>  
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Typography variant="subtitle1" color="text.secondary">
                {iref}
              </Typography>
              </Box>
            </Box>
                    
          }        
          action={            
            <IconButton aria-label="settings" onClick={this.handleEditClick}>
              <EditIcon sx={{ color: this.state.edit ? "#8f241d" : "#e3e3e5" }}/>
            </IconButton>
          }
          title={name}
          subheader={description}
          titleTypographyProps={{variant:'h5' }}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>

        <Box sx={{ display: 'block', flexDirection: 'column', width: '20%'}}>          
        </Box>
        
        <Box sx={{ flexDirection: 'column', display: "flex", width: '30%', justifyContent:"left" }}>

          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            {this.state.edit ? 
              ""
              : 
              <Typography variant="body2" color="text.secondary">
                {location_details ? "Location: " + location_details : ""}
              </Typography>
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "start", justifyContent:"left" }}>
            {this.state.edit ? 
              <DropDown
                name="Status"
                value={status !== undefined ? status : ''}
                doptions={BackOfficeConfig.USER_STATUSES_WITH_ICONS}
                hasIcons={true}   
                minWidth={120}
                maxWidth={220}
                onDark={false}
                onChangeFunction={this.changeStatus}
                marginTop={0}
                marginLeft={0}
                margin={0}
              />
              : 
              ""
            }
          </Box>
        </Box>
        
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '50%', marginRight: 1 }}>
        <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right" }}>
            {this.state.edit ? 
              <TextField
                id="name"
                label="Name"
                value={name || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.nameValid === false}
              />
              : 
              ""
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right" }}>
            {this.state.edit ? 
              <TextField
                id="sn"
                label="SN"
                value={sn || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.snValid === false}
              />
              : 
              <Typography variant="body2" color="text.secondary">
                {sn}
              </Typography>
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right" }}>
            {this.state.edit ? 
              <TextField
                id="description"
                label="Description"
                value={description || ''}
                variant="outlined"
                onChange={this.changeValue}
                sx={{minWidth: "90%", marginTop: 1}}
                onFocus={event => { event.target.select(); }}
                inputProps={{maxLength:"255"}}
                error={this.state.descriptionValid === false}
              />
              : 
              ""
            }
          </Box>
          <Box sx={{ display: 'block', flexDirection: 'row', textAlign: "end", justifyContent:"right", marginTop: 1 }}>            
            <Typography variant="body2" color="text.secondary">
              {activationToken}
              <IconButton aria-label="Generate" onClick={() => {navigator.clipboard.writeText(activationToken)}} sx={{marginTop: 0, marginLeft: 0}}>
                <ContentCopyIcon titleAccess="Copy to clipboard" fontSize="small"/>
              </IconButton>
            </Typography>
          </Box>                    
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "end", justifyContent:"right", alignItems: "right", paddingTop: "2px", marginRight: 1 }}>
        {saveButtonVisible ?
          <Button
            variant="contained"
            size="small"
            sx={{ color: "#e3e3e5" }}
            startIcon={<SaveIcon />}
            onClick={this.pushData}
          >
            Save
          </Button>
          : ""
        }
      </Box>
          
      <Box sx={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
        <Box sx={{ flexDirection: 'column', justifyContent:"left", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "flex", textAlign: "start", justifyContent:"left"}}>
            <UpdateIcon size="small" titleAccess="Modified"/>&nbsp;
            {modifiedOn}            
          </CardActions>          
        </Box>          
        <Box sx={{ flexDirection: 'column', justifyContent:"right", width: '100%' }}>
          <CardActions disableSpacing sx={{display: "block", textAlign: "end" }}>                
           {/*  {username}, {card}    */}
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          </CardActions>
        </Box>
      </Box>

      

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit sx={{width: "100% !important"}}>
        <CardContent>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: "middle", justifyContent:"center", alignItems: "center", paddingTop: "8px"}}>
            
          </Box>



          
          </CardContent>

        </Collapse>

      </Box>
    </Card>
   
    );

  }
}

export default POS;
