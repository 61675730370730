export default class Config {
    
    //Base & API
    static get BASE_URL() {return 'https://cartos.kalopsia.pro'};
    static get BASE_API_URL() {return '/api/1.0/'};
    static get AUTH_API_URL() {return this.BASE_API_URL+ 'auth/token/'};
    static get AUTH_REFRESH_API_URL() {return this.BASE_API_URL+ 'auth/token/refresh/'};

    //User
    static get USER_API_URL() {return this.BASE_API_URL+"user/"};
    static get USER_ADD_API_URL() {return this.USER_API_URL+"add/"};
    static get WHOAMI_API_URL() {return this.USER_API_URL + "whoami/"};
    static get LOGOUT_API_URL() {return this.USER_API_URL + "logout/"};
    static get USER_UPDATE_API_URL() {return this.USER_API_URL + "updateAccount/"};
    static get FORGOTPASS_API_URL() {return this.USER_API_URL + "forgotPassword/"};
    static get RESETPASS_API_URL() {return this.USER_API_URL + "resetPassword/"};
    static get VALIDATE_ACCOUNT_API_URL() {return this.USER_API_URL + "validate/"};
    static get API_URL_USERS_PROFILES() {return this.USER_API_URL + "usersProfiles/"};

    //Product
    static get API_URL_PRODUCT () {return this.BASE_API_URL+"product/"};
    static get API_URL_PRODUCT_ADD () {return this.API_URL_PRODUCT+"add/"};
    static get API_URL_PRODUCT_CLASS () {return this.BASE_API_URL+"productClass/"};
    static get API_URL_PRODUCT_CLASS_ADD () {return this.API_URL_PRODUCT_CLASS+"add/"};
    static get API_URL_PRODUCT_CATEGORY () {return this.BASE_API_URL+"productCategory/"};
    static get API_URL_PRODUCT_CATEGORY_ADD () {return this.API_URL_PRODUCT_CATEGORY+"add/"};
    static get API_URL_PRODUCT_SUBCATEGORY () {return this.BASE_API_URL+"productSubCategory/"};
    static get API_URL_PRODUCT_SUBCATEGORY_ADD () {return this.API_URL_PRODUCT_SUBCATEGORY+"add/"};
    static get API_URL_PRODUCT_UPDATEKEYS () {return this.API_URL_PRODUCT+"list_updateKeys/"};
    static get API_URL_PRODUCT_ALLERGENS () {return this.API_URL_PRODUCT+"list_allergens/"};
    static get API_URL_PRODUCT_FILTER () {return this.API_URL_PRODUCT+"filter/"};
    static get API_URL_PRODUCT_CATEGORY_WITH_SUBCATEGORIES () {return this.API_URL_PRODUCT_CATEGORY+"withSubCategories/"};
    static get API_URL_VATS () {return this.API_URL_PRODUCT+"list_vats/"};
    static get API_URL_PRODUCT_ADD_IMAGE () {return this.API_URL_PRODUCT+"addImage/"};
    static get API_URL_PRODUCT_REMOVE_IMAGE () {return this.API_URL_PRODUCT+"removeImage/"};

    //Combo
    static get API_URL_COMBO () {return this.BASE_API_URL+"combo/"};
    static get API_URL_COMBO_ADD () {return this.API_URL_COMBO+"add/"};
    static get API_URL_COMBO_FILTER () {return this.API_URL_COMBO+"filter/"};
    static get API_URL_COMBO_ADD_IMAGE () {return this.API_URL_COMBO+"addImage/"};
    static get API_URL_COMBO_REMOVE_IMAGE () {return this.API_URL_COMBO+"removeImage/"};
    static get API_URL_COMBO_ADD_PRODUCT_TO_COMBO () {return this.API_URL_COMBO+"addProduct/"};
    static get API_URL_COMBO_REMOVE_PRODUCT_FROM_COMBO () {return this.API_URL_COMBO+"removeProduct/"};

    

    //Health
    static get API_URL_ALLERGENS () {return this.BASE_API_URL+"allergens/"};

    //Location
    static get API_URL_LOCATIONS () {return this.BASE_API_URL+"location/"};
    static get API_URL_LOCATIONS_ADD () {return this.API_URL_LOCATIONS+"add/"};
    static get API_URL_LOCATIONS_ADD_IMAGE () {return this.API_URL_LOCATIONS+"addImage/"};
    static get API_URL_LOCATIONS_REMOVE_IMAGE () {return this.API_URL_LOCATIONS+"removeImage/"};
    static get API_URL_LOCATIONS_POS_ADD () {return this.API_URL_LOCATIONS+"addPOS/"};
    static get API_URL_LOCATIONS_SIMPLE () {return this.API_URL_LOCATIONS+"simple/"};
    static get API_URL_LOCATIONSGROUPS () {return this.BASE_API_URL+"locationGroup/"};
    static get API_URL_LOCATIONSGROUPS_ADD () {return this.API_URL_LOCATIONSGROUPS+"add/"};
    static get API_URL_EMPLOYEES () {return this.BASE_API_URL+"employee/"};
    static get API_URL_EMPLOYEES_ADD () {return this.API_URL_EMPLOYEES+"add/"};
    //static get API_URL_EMPLOYEES_UPDATELEVEL () {return this.API_URL_EMPLOYEES+"updateLevel/"};
    static get API_URL_LOCATIONEMPLOYEES () {return this.BASE_API_URL+"locationEmployee/"};
    static get API_URL_LOCATIONEMPLOYEES_ADD () {return this.API_URL_LOCATIONEMPLOYEES+"add/"};
    //static get API_URL_LOCATIONEMPLOYEES_UPDATELEVEL () {return this.API_URL_LOCATIONEMPLOYEES+"add/"};
    static get API_URL_LOCATIONEMPLOYEES_UPDATE_LEVEL () {return this.API_URL_LOCATIONEMPLOYEES+"updateLevel/"};
    static get API_URL_LOCATIONEMPLOYEES_REMOVE () {return this.API_URL_LOCATIONEMPLOYEES+"remove/"};
    static get API_URL_LOCATION_POS () {return this.BASE_API_URL+"pos/"};
    
    static get API_URL_LOCATION_SCHEDULE () {return this.BASE_API_URL+"locationSchedule/"};
    static get API_URL_LOCATION_SCHEDULE_ADD () {return this.API_URL_LOCATION_SCHEDULE+"add/"};
    static get API_URL_LOCATION_SCHEDULE_REMOVE () {return this.API_URL_LOCATION_SCHEDULE+"remove/"};

    //AddressBook
    static get API_URL_ADDRESS () {return this.BASE_API_URL+"address/"};
    static get API_URL_GEODATA () {return this.API_URL_ADDRESS+"getGeoStructure/"};
    static get API_URL_COMPANY () {return this.BASE_API_URL+"company/"};
    static get API_URL_COMPANY_ADD () {return this.API_URL_COMPANY+"add/"};
    static get API_URL_PERSON () {return this.BASE_API_URL+"person/"};
    static get API_URL_PERSON_ADD () {return this.API_URL_PERSON+"add/"};
    static get API_URL_COMPANY_EXTAPI () {return this.API_URL_COMPANY+"callExternalAPI/"};
    static get API_URL_COUNTRY () {return this.BASE_API_URL+"country/"};
    static get API_URL_COUNTY () {return this.BASE_API_URL+"county/"};
    static get API_URL_CITY () {return this.BASE_API_URL+"city/"};

    //Lists
    static get API_URL_LIST_PRICE () {return this.BASE_API_URL+"priceList/"};
    static get API_URL_LIST_PROMOTION () {return this.BASE_API_URL+"promotionList/"};
    static get API_URL_LIST_COMPANY () {return this.BASE_API_URL+"companyList/"};
    static get API_URL_LIST_PRODUCT () {return this.BASE_API_URL+"pcList/"};
    static get API_URL_LIST_PRODUCT_FILTER () {return this.API_URL_LIST_PRODUCT+"filter/"};
    static get API_URL_LIST_COMBO () {return this.API_URL_LIST_PRODUCT+"combos/"};
    static get API_URL_LIST_COMBO_FILTER () {return this.API_URL_LIST_PRODUCT+"combosFilter/"};
    static get API_URL_LIST_ACQUISITION () {return this.BASE_API_URL+"acquisitionList/"};

    //Config
    static get API_URL_LANGUAGE () {return this.BASE_API_URL+"config_languages/"};
    static get API_URL_LANGUAGE_ADD () {return this.API_URL_LANGUAGE+"add/"};
    static get API_URL_CONFIG () {return this.BASE_API_URL+"config/"};
    static get API_URL_CONFIG_ADD () {return this.API_URL_CONFIG+"add/"};
    static get API_URL_CONFIG_FRONT () {return this.API_URL_CONFIG+"getConfigFront/"};
    static get API_URL_CONFIG_IDENTITY_TEXT () {return this.BASE_API_URL+"config_identityText/"};
    static get API_URL_CONFIG_IDENTITY_TEXT_ADD () {return this.API_URL_CONFIG_IDENTITY_TEXT+"add/"};
    static get API_URL_CONFIG_IDENTITY_IMAGE () {return this.BASE_API_URL+"config_identityImage/"};
    static get API_URL_CONFIG_IDENTITY_IMAGE_ADD () {return this.API_URL_CONFIG_IDENTITY_IMAGE+"add/"};
    static get API_URL_CONFIG_SALESAPP () {return this.BASE_API_URL+"config_salesApp/"};
    static get API_URL_CONFIG_SALESAPP_ADD () {return this.API_URL_CONFIG_SALESAPP+"add/"};
    static get API_URL_CONFIG_MOBILEAPP () {return this.BASE_API_URL+"config_mobileApp/"};
    static get API_URL_CONFIG_MOBILEAPP_ADD () {return this.API_URL_CONFIG_MOBILEAPP+"add/"};
    static get API_URL_CONFIG_WEBSITE () {return this.BASE_API_URL+"config_website/"};
    static get API_URL_CONFIG_WEBSITE_ADD () {return this.API_URL_CONFIG_WEBSITE+"add/"};

    //Client
    static get API_URL_CLIENT() {return this.BASE_API_URL+"client/"};
    static get API_URL_CLIENT_ADD() {return this.API_URL_CLIENT+"add/"};
    static get API_URL_CLIENT_ERASE() {return this.API_URL_CLIENT+"erase/"};
    static get API_URL_CLIENT_FAMILY() {return this.BASE_API_URL+"clientFamily/"};
    static get API_URL_CLIENT_FAMILY_ALTER() {return this.API_URL_CLIENT_FAMILY+"alterFamily/"};
    static get API_URL_CLIENT_FAMILY_MEMBERS() {return this.API_URL_CLIENT_FAMILY+"getMembers/"};
    static get API_URL_CLIENT_FAMILY_ALTERMEMBER() {return this.API_URL_CLIENT_FAMILY+"alterMember/"};

    static get API_URL_CLIENT_PRO() {return this.BASE_API_URL+"clientProfessional/"};
    static get API_URL_CLIENT_PRO_ALTER() {return this.API_URL_CLIENT_PRO+"alterProfessional/"};
    static get API_URL_CLIENT_PRO_MEMBERS() {return this.API_URL_CLIENT_PRO+"getMembers/"};
    static get API_URL_CLIENT_PRO_ALTERMEMBER() {return this.API_URL_CLIENT_PRO+"alterMember/"};

    //Reports
    static get API_URL_REPORTS() {return this.BASE_API_URL+"reports/"};
    static get API_URL_REPORTS_DEMOGRAPHIC() {return this.API_URL_REPORTS+"demographic/"};
    static get API_URL_REPORTS_GEOGRAPHIC() {return this.API_URL_REPORTS+"geographic/"};
    static get API_URL_REPORTS_PRODUCTS() {return this.API_URL_REPORTS+"products/"};
    static get API_URL_REPORTS_CLIENTS() {return this.API_URL_REPORTS+"clients/"};
    static get API_URL_REPORTS_FINANCIAL_SALES() {return this.API_URL_REPORTS+"financial_sales/"};
    static get API_URL_REPORTS_FINANCIAL_SALES_POST() {return this.API_URL_REPORTS+"financial_sales_post/"};
}
