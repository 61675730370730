import { alpha, createTheme, useTheme } from '@mui/material';
let theme = createTheme();

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
export const ChartOptions_bar1 = {
  chart: {
    // type: 'bar',
    background: 'transparent',
    stacked: false,
    toolbar: {
      show: false,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: []
      }
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp) {
          return new Date(timestamp).toDateString()
        }
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      }
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1
        }
      }
    }
  },
  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
    show: true,
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  xaxis: {
      tickPlacement: 'on',
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      /* categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ], */
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary
        }
      }
  },
  yaxis: {
    labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(0)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  }
}

export const ChartOptions_bar2k = {
  chart: {
    background: 'transparent',
    stacked: false,
    toolbar: {
      show: false
    }
  },
  colors: [theme.palette.primary.main, alpha(theme.palette.primary.main, 0.25)],
  dataLabels: {
    enabled: true,
    formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}K` : `${thousandsSeparator(value)}K`)
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: ['transparent'],
    show: true,
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  yaxis: {
    type: 'category',
    reversed: true,
    axisBorder: {
      color: theme.palette.divider,
      show: true
    },
    axisTicks: {
      color: theme.palette.divider,
      show: true
    }, 
    labels: {
      offsetY: 5,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  },
  xaxis: {
    labels: {
      formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}K` : `${thousandsSeparator(value)}K`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  }
}

export const ChartOptions_bar2 = {
  chart: {
    background: 'transparent',
    sparkline: {
        enabled: false
    },
    stacked: false,
    toolbar: {
      show: true
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp) {
          return new Date(timestamp).toDateString()
        }
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      }
    }
  },
  colors: [theme.palette.warning.dark, alpha(theme.palette.warning.dark, 0.25)],
  dataLabels: {
    enabled: true,
    formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}` : `${thousandsSeparator(value)}`)
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: ['transparent'],
    show: true,
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  yaxis: {
    reversed: true,
    type: 'category',
    axisBorder: {
      color: theme.palette.divider,
      show: true
    },
    axisTicks: {
      color: theme.palette.divider,
      show: true
    }, 
    labels: {
      offsetY: 5,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  },
  xaxis: {
    labels: {
      formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}` : `${thousandsSeparator(value)}`),
      offsetX: -10,
      hideOverlappingLabels: true,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  }
}

export const ChartOptions_bar2v = {
  chart: {
    background: 'transparent',
    stacked: false,
    toolbar: {
      show: true
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp) {
          return new Date(timestamp).toDateString()
        }
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      }
    }
  },
  colors: [alpha(theme.palette.warning.dark, 0.25), theme.palette.warning.dark],
  dataLabels: {
    formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}` : `${thousandsSeparator(value)}`),
    enabled: false
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: ['transparent'],
    show: true,
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  yaxis: {
    type: 'category',
    axisBorder: {
      color: theme.palette.divider,
      show: true
    },
    axisTicks: {
      color: theme.palette.divider,
      show: true
    }, 
    labels: {
      formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}` : `${thousandsSeparator(value)}`),
      offsetY: 5,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  },
  xaxis: {
    labels: {
      offsetX: 0,
      style: {
        colors: theme.palette.text.secondary
      }
    }
  }
}

export const ChartOptions_donut =  {
  chart: {
    // type: 'pie',
    background: 'transparent'
  },
  colors: [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.error.main
  ],
  dataLabels: {
    formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}` : `${thousandsSeparator(value)}`),
    enabled: false
  },
  // labels:['a', 'b', 'c', 'd', 'e'],
  legend: {
    show: false
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      customScale: 1,
    }
  },
  states: {
    active: {
      filter: {
        type: 'none'
      }
    },
    hover: {
      filter: {
        type: 'none'
      }
    }
  },
  stroke: {
    width: 0
  },
  theme: {
    mode: theme.palette.mode
  },
  tooltip: {
    fillSeriesColor: false
  } 
}

export const ChartOptions_pie =  {
  chart: {
    background: 'transparent'
  },
  colors: [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.error.main
  ],
  dataLabels: {
    //formatter: (value) => (value > 0 ? `${thousandsSeparator(value)}` : `${thousandsSeparator(value)}`),
    enabled: true,
  },
  // labels:['a', 'b', 'c', 'd', 'e'],
  legend: {
    show: false,
    position: 'bottom'
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      customScale: 1,
    }
  },
  states: {
    active: {
      filter: {
        type: 'none'
      }
    },
    hover: {
      filter: {
        type: 'none'
      }
    }
  },
  stroke: {
    width: 0
  },
  theme: {
    mode: theme.palette.mode
  },
  tooltip: {
    fillSeriesColor: false
  }
}

export const ChartOptions_line = {
  chart: {
    // type: 'bar',
    background: 'transparent',
    stacked: false,
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: []
      }
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp) {
          return new Date(timestamp).toDateString()
        }
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      }
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1
        }
      }
    }
  },
  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main, theme.palette.error.main, theme.palette.success.main],
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main, theme.palette.error.main, alpha(theme.palette.success.main,0)],
    show: true,
    curve: 'smooth', // 'smooth', 'straight', 'stepline'
    lineCap: 'square', // 'butt', 'square', 'round'
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  xaxis: {
      tickPlacement: 'on',
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      /* categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ], */
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary
        }
      }
  },
  markers: {
    size: [0, 4, 4, 4]
  },
  yaxis: [
    {
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: false,
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: false,
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: false,
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(0))}K` : `${value.toFixed(0)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }}
  ]
}

export const ChartOptions_line1 = {
  chart: {
    // type: 'bar',
    background: 'transparent',
    stacked: false,
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: []
      }
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp) {
          return new Date(timestamp).toDateString()
        }
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      }
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1
        }
      }
    }
  },
  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
    show: true,
    curve: 'smooth', // 'smooth', 'straight', 'stepline'
    lineCap: 'square', // 'butt', 'square', 'round'
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  xaxis: {
      tickPlacement: 'on',
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      /* categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ], */
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary
        }
      }
  },
  markers: {
    size: [0, 0, 3, 3]
  },
  yaxis: [
    {
      show: true,
      min: (min0) => {
        console.log('Min1:', min0);
        return 0; 
      },
      max: (max) => {
        console.log('Max1:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
    }},
    {
      show: true,
      min: (min) => {
        // min = Math.min(["series"][0]["data"]);
        console.log("Min2:", min);
        return min;
      },
      max: (max) => {
        console.log('Max2:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: true,
      min: (min) => {
        console.log('Min3:', min);
        return min; 
      },
      max: (max) => {
        console.log('Max3:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: true,
      min: (min) => {
        console.log('Min4:', min);
        return min; 
      },
      max: (max) => {
        console.log('Max4:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }}
  ]
}

export const ChartOptions_line11 = {
  chart: {
    // type: 'bar',
    background: 'transparent',
    stacked: false,
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: []
      }
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp) {
          return new Date(timestamp).toDateString()
        }
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      }
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: false,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1
        }
      }
    }
  },
  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1,
    type: 'solid'
  },
  grid: {
    borderColor: theme.palette.divider,
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      colors: {
        backgroundBarColors: Array
      }
    }
  },
  stroke: {
    colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
    show: true,
    curve: 'smooth', // 'smooth', 'straight', 'stepline'
    lineCap: 'square', // 'butt', 'square', 'round'
    width: 2
  },
  theme: {
    mode: theme.palette.mode
  },
  xaxis: {
      tickPlacement: 'on',
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      /* categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ], */
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary
        }
      }
  },
  markers: {
    size: [0, 0, 3, 3]
  },
  yaxis: [
    {
      show: true,
      min: (min0) => {
        console.log('Min1:', min0);
        return 0; 
      },
      max: (max) => {
        console.log('Max1:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
    }},
    {
      show: true,
      min: (min) => {
        // min = Math.min(["series"][0]["data"]);
        console.log("Min2:", min);
        return min;
      },
      max: (max) => {
        console.log('Max2:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: true,
      min: (min) => {
        console.log('Min3:', min);
        return min; 
      },
      max: (max) => {
        console.log('Max3:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }},
    {
      opposite: true,
      show: true,
      min: (min) => {
        console.log('Min4:', min);
        return min; 
      },
      max: (max) => {
        console.log('Max4:', max);
        return max; 
      },
      labels: {
      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
      offsetX: -10,
      style: {
        colors: theme.palette.text.secondary
      }
    }}
  ]
}