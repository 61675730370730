import React from "react";
import { alpha, Avatar, createTheme, AspectRatio, CardOverflow, Box, Card, Grid, CardActions, CardContent, CardHeader, Divider, Stack, SvgIcon, Typography, Button, TextField, Menu, MenuItem, Select, Table, TableContainer, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import DropDown from '../DropDown';
import { format, intervalToDuration } from 'date-fns';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Chart from './chart';

import Client from '../Client';
import Config from '../ClientConfig';
import BackOfficeConfig from '../../Config';

let theme = createTheme();

const objectToCsv = function (data) {
  const csvRows = [];
  /* Get headers as every csv data format 
  has header (head means column name)
  so objects key is nothing but column name 
  for csv data using Object.key() function.
  We fetch key of object as column name for 
  csv */
  const headers = Object.keys(data[0]);
  /* Using push() method we push fetched 
     data into csvRows[] array */
  csvRows.push(headers.join(','));
  // Loop to get value of each objects key
  for (const row of data) {
      const values = headers.map(header => {
          const val = row[header]
          return `"${val}"`;
      });
      // To add, separator between each value
      csvRows.push(values.join(','));
  }
  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n');
};
// const data = [{'year': 2023, 'month': 1, 'total': 7568271.760000003, 'trans': 264444, 'nrloc': 34}, {'year': 2023, 'month': 2, 'total': 7181021.329999989, 'trans': 245177, 'nrloc': 35}, ];
// // Data passed as parameter 
// const csvData = objectToCsv(data);
// console.log(csvData); 


function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

function getStringLength(str) {
    return str.length;
  }

function generateYearsBetween(startYear = 2000, endYear) {
  const endDate = endYear || new Date().getFullYear();
  let years = [];
  while (startYear <= endDate) {
    years.push(startYear);
    startYear++;
  }
  return years;
}
let end = 0;
let start = 0;

end = new Date().getFullYear();
start = end - 3;
const yearsArray = generateYearsBetween(start, end);
yearsArray.reverse();
// console.log(yearsArray);
let years ={}
years = yearsArray.map(x => ({value: x}));
// console.log(years);

const now0 = new Date();
now0.setHours(0,0,0,0);
/* console.log(now0); */

const now = now0;
const csvdate = format(now, 'yyyy-MM-dd'); 
const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
const firstWday = new Date(new Date(now.setDate(now.getDate() - now.getDay()+1)).setHours(0,0,0,0));
const lastWday = new Date(new Date(now.setDate(now.getDate() - now.getDay()+7)).setHours(23,59,59,999));
const lastDay = new Date(new Date(now.getFullYear(), now.getMonth() + 1, 0).setHours(23,59,59,999));

/* console.log(firstDay); 
console.log(firstWday);
console.log(now);
console.log(lastWday);
console.log(lastDay);  */

class OverviewSales_multiple extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      location: [0],
      locationGroup: 0,
      year: new Date().getFullYear(),
      // year: "2023",
      value: "1",
      locations: [{'iref': 0, 'name': "All Locations"}],
      locationsGroups: [{'iref': 0, 'name': "All Groups"}],
      filterData: {}
    };

    this.pushData = this.pushData.bind(this);
    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.queryData = { };
  }

  componentDidMount() {
    this.selectYear = this.selectYear.bind(this);
    this.selectLocationGroup = this.selectLocationGroup.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.getData ();
    this.pushData ();
  }

  getData ( ) {  
    let data = { 
      "location_status": 1,
      "locationGroup_status": 1,
      "orderBy_name": true      // order products by name
    }

    this.setState ( { filterData: data } );

    let locations = this.state.client.getData ( Config.API_URL_LOCATIONS_SIMPLE, data );
    this.setState ( { locations: locations.locations });
    let locationsGroups = this.state.client.getData ( Config.API_URL_LOCATIONSGROUPS, data );
    this.setState ( { locationsGroups: locationsGroups.locationsGroups });
  }

  pushData ( ) {  
    let queryData = { 
      "type": 0,
      year: this.state.year,
      location: this.state.location,
      location_status: 1,
      location_group: this.state.locationGroup
    }
	  let data = this.state.client.pushData ( Config.API_URL_REPORTS_FINANCIAL_SALES_POST, queryData );
    // console.log(data);
    if ( data ) {
      this.setState ( { sales_month: data });
    }
  }
  
  doSync ( ) {
    this.pushData ();
  }

  selectYear = (event) => {
    this.setState (
      {year: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  selectLocation = (event) => {
    this.setState (
      {location: event.target.value},
      function(){
        if (this.state.location[0] == 0) {
          // console.log('location starts ALL');
          delete this.state.location[0];
          // console.log(this.state.location);
        } 
        // console.log(this.state.location.slice(-1));
        if (this.state.location.slice(-1) == 0) {
          // console.log('location contains ALL');
          this.setState({location:[]});
          this.setState({location:[0]});
        }
        this.doSync ( );
        
      }
      );
  } 

  selectLocationGroup = (event) => {
    this.setState (
      {locationGroup: event.target.value},
      function(){
        this.doSync ( );
      }
      );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  }

  onDownload = () => {
    const link = document.createElement("a");
    link.download = "salesOverview_" + csvdate + ".csv";
    // console.log(this.state.saleslocation_list);
    // console.log(this.state.saleslocation_list.result);
    let csvData = objectToCsv(this.state.sales_month.sales);
    let csvHeader = "Sales Overview"+ "\n" + "Location Group: "+ this.state.locationGroup + "\n" + "Location: "+ this.state.location ;
    let csv = csvHeader + "\n" + csvData;
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    // console.log(this.state.sales_month);
    // console.log(this.state.sales_month.sales);
    // console.log(link);
    link.click();
  };

  render() {
    let locations = [{'iref': 0, 'name': "All Locations"}];
    if (this.state.locations){
      Object.keys(this.state.locations).forEach(key => {
        let fData = this.state.locations [ key ];
        let myData = {'iref': fData.iref, 'name': fData.name};
        locations.push ( myData );
      });
    }

    let locationsGroups = [{'iref': 0, 'name': "All Groups"}];
    if (this.state.locationsGroups){
      Object.keys(this.state.locationsGroups).forEach(key => {
        let fData = this.state.locationsGroups [ key ];
        let myData = {'iref': fData.iref, 'name': fData.name};
        locationsGroups.push ( myData );
      });
    }

    let sales = [];
    let salesT = [];
    let salesA = [];
    let countL = [];
    let salesD = [];
    let salesD_LY = [];
    let salesM = [];
		let salesM_LY = [];
    let salesM_new_trans = [];
    let salesM_LY_new_trans = [];
    let salesM_new = [];
		let salesM_LY_new = [];
    let salesW = [];
		let salesW_LY = [];
    let salesW_trans = [];
		let salesW_LY_trans = [];

    if ( this.state.sales_month ) {
        let sales_month = this.state.sales_month;
        if ( sales_month.salesM !== undefined ) salesM = sales_month.salesM;
        if ( sales_month.salesM_LY !== undefined ) salesM_LY = sales_month.salesM_LY;
        if (this.state.sales_month.sales) {
          Object.keys(this.state.sales_month.sales).forEach(key => {
            let fData = this.state.sales_month.sales [ key ];
            let sign ="";
            if (fData.month < 10) {sign = "-0"} else {sign = "-"};
            let mySales = {'x': fData.year+sign+fData.month, 'y': fData.total};
            sales.push ( mySales );
            let myLocs = {'x': fData.year+sign+fData.month, 'y': fData.nrloc};
            countL.push ( myLocs );
            let myTrans = {'x': fData.year+sign+fData.month, 'y': fData.trans};
            salesT.push ( myTrans );
            let myAvg = {'x': fData.year+sign+fData.month, 'y': fData.total/fData.trans};
            salesA.push ( myAvg );
          });
        } 
        if (this.state.sales_month.salesD) {
          let days = "";
          Object.keys(this.state.sales_month.salesD).forEach(key => {
            days = BackOfficeConfig.WEEKDAYS_EN [ parseInt(key)+1 ]
            let fData = this.state.sales_month.salesD [ key ];
            let myData = {'x': days, 'y': fData.avg};
            salesD.push ( myData );
          });
        }
        if (this.state.sales_month.salesD_LY) {
          let days = "";
          Object.keys(this.state.sales_month.salesD_LY).forEach(key => {
            days = BackOfficeConfig.WEEKDAYS_EN [ parseInt(key)+1 ]
            let fData = this.state.sales_month.salesD_LY [ key ];
            let myData = {'x': days, 'y': fData.avg};
            salesD_LY.push ( myData );
          });
        } 
         
        if (this.state.sales_month.salesM_new) {
          Object.keys(this.state.sales_month.salesM_new).forEach(key => {
            let fData = this.state.sales_month.salesM_new [ key ];
            let myData = {'x': fData.month, 'y': fData.value};
            salesM_new.push ( myData );
            let myTrans = {'x': fData.month, 'y': fData.transaction};
            salesM_new_trans.push ( myTrans );
          });
        } 
        if (this.state.sales_month.salesM_LY_new) {
          Object.keys(this.state.sales_month.salesM_LY_new).forEach(key => {
            let fData = this.state.sales_month.salesM_LY_new [ key ];
            let myData = {'x': fData.month, 'y': fData.value};
            salesM_LY_new.push ( myData );
            let myTrans = {'x': fData.month, 'y': fData.transaction};
            salesM_LY_new_trans.push ( myTrans );
          });
        } 
        if (this.state.sales_month.salesW) {
          Object.keys(this.state.sales_month.salesW).forEach(key => {
            let fData = this.state.sales_month.salesW [ key ];
            let myData = {'x': fData.week, 'y': fData.value};
            salesW.push ( myData );
            let myTrans = {'x': fData.week, 'y': fData.transaction};
            salesW_trans.push ( myTrans );
          });
        } 
        if (this.state.sales_month.salesW_LY) {
          Object.keys(this.state.sales_month.salesW_LY).forEach(key => {
            let fData = this.state.sales_month.salesW_LY [ key ];
            let myData = {'x': fData.week, 'y': fData.value};
            salesW_LY.push ( myData );
            let myTrans = {'x': fData.week, 'y': fData.transaction};
            salesW_LY_trans.push ( myTrans );
          });
        } 
    }

    // let arrayvalue = salesM_new.map(({ y }) => y);  // => parseInt(y)
    let maxM_LY_new = Math.max(...salesM_LY_new.map(({ y }) => y));
    let maxM_new = Math.max(...salesM_new.map(({ y }) => y));
    let maxM_LY_new_trans = Math.max(...salesM_LY_new_trans.map(({ y }) => y));
    let maxM_new_trans = Math.max(...salesM_new_trans.map(({ y }) => y));
    let maxM = parseInt(Math.max(maxM_LY_new,maxM_new));
    let p1 = maxM.toString().length -1;
    maxM = Math.ceil(maxM/10**p1 )*10**p1;
    let maxM_trans = parseInt(Math.max(maxM_LY_new_trans,maxM_new_trans));
    let p2 = maxM_trans.toString().length -1;
    maxM_trans = Math.ceil(maxM_trans/10**p2 )*10**p2;
   
    let maxW_LY = Math.max(...salesW_LY.map(({ y }) => y));
    let maxW = Math.max(...salesW.map(({ y }) => y));
    let maxW_LY_trans = Math.max(...salesW_LY_trans.map(({ y }) => y));
    let maxW_trans = Math.max(...salesW_trans.map(({ y }) => y));
    let maxWm = parseInt(Math.max(maxW_LY,maxW));
    let p3 = maxWm.toString().length -1;
    maxWm = Math.ceil(maxWm/10**p3 )*10**p3;
    let maxWm_trans = parseInt(Math.max(maxW_LY_trans,maxW_trans));
    let p4 = maxWm_trans.toString().length -1;
    maxWm_trans = Math.ceil(maxWm_trans/10**p4 )*10**p4;


    return (
        <Card variant="outlined" style={{ height: '600px' }} sx={{ paddingTop:"6px", borderRadius:"16px", height: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{ padding: '16px', alignItems: 'baseline' }}>
              <TextField
                id="standard-select-years"
                select
                // label="Select"
                defaultValue={end}
                // helperText="Select Years"
                variant="standard"
                sx={{ width: 100}}
                onChange={this.selectYear}
              >
                  {years.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="standard-select-locationgroup"
                select
                // label="Select"
                defaultValue="0"
                // helperText="Select Location Group"
                variant="standard"
                sx={{ width: 180}}
                onChange={this.selectLocationGroup}
              >
                  {locationsGroups.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <Select
                id="standard-select-location"
                multiple
                // label="Select"
                value={this.state.location}
                // defaultValue="0"
                // helperText="Select Location"
                variant="standard"
                sx={{ width: 180, maxHeight: 250}}
                onChange={this.selectLocation}
              >
                  {locations.map((option) => (
                  <MenuItem key={option.iref} value={option.iref}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              </Box >
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="tabs example">
                <Tab style= {{}} icon={<BarChartIcon />} value="1" />
                <Tab classes={{ tab: {width: 50}, tabRoot: {width: 50}, root: {width: 50} }} label="Month" value="2" />
                <Tab label="Week" value="3" />
                <Tab label="wDay" value="4" />
              </Tabs>  
          </Box>
        <Box>
            {this.state.value === "1" && (
                  <Chart
                  height={350}
                  type='line0'
                  series={[
                    {
                    name: 'Monthly sales',
                    type: 'bar',
                    data: sales
                    },
                    {
                      name: 'Monthly transactions',
                      type: 'line',
                      data: salesT
                    },
                    {
                      name: 'Average transaction',
                      type: 'line',
                      data: salesA
                    },
                    {
                      name: 'Locations count',
                      type: 'line',
                      data: countL
                    }
                  ]}
                  yaxis={[
                    {
                      seriesName: 'Monthly sales',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                      }/* ,
                      title: {
                        text: "Columns"
                      } */
                    },
                    {
                      seriesName: 'Monthly transactions',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                      }/* ,
                      title: {
                        text: "Line"
                      } */
                    },
                    {
                      seriesName: 'Average transaction',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                      }/* ,
                      title: {
                        text: "Line"
                      } */
                    },
                    {
                      seriesName: 'Locations count',
                      axisTicks: {
                        show: true
                      },
                      axisBorder: {
                        show: true,
                      }/* ,
                      title: {
                        text: "Line"
                      } */
                    }
                  ]}
                  width={"100%"}
                />
            )}
            {this.state.value === "2" && (
              <Chart
              height={350}
              type="line11"
              options={{
                chart: {
                  // type: 'bar',
                  background: 'transparent',
                  stacked: false,
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true | '<img src="/static/icons/reset.png" width="20">',
                      customIcons: []
                    }
                  },
                  export: {
                    csv: {
                      filename: undefined,
                      columnDelimiter: ',',
                      headerCategory: 'category',
                      headerValue: 'value',
                      dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                      }
                    },
                    svg: {
                      filename: undefined,
                    },
                    png: {
                      filename: undefined,
                    }
                  },
                  zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false,
                    zoomedArea: {
                      fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                      },
                      stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                      }
                    }
                  }
                },
                colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 1,
                  type: 'solid'
                },
                grid: {
                  borderColor: theme.palette.divider,
                  strokeDashArray: 2,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true
                    }
                  }
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    barHeight: '70%',
                    distributed: false,
                    colors: {
                      backgroundBarColors: Array
                    }
                  }
                },
                stroke: {
                  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                  show: true,
                  curve: 'smooth', // 'smooth', 'straight', 'stepline'
                  lineCap: 'square', // 'butt', 'square', 'round'
                  width: 2
                },
                theme: {
                  mode: theme.palette.mode
                },
                xaxis: {
                    tickPlacement: 'on',
                    axisBorder: {
                      color: theme.palette.divider,
                      show: true
                    },
                    axisTicks: {
                      color: theme.palette.divider,
                      show: true
                    },
                    labels: {
                      offsetY: 5,
                      style: {
                        colors: theme.palette.text.secondary
                      }
                    }
                },
                markers: {
                  size: [0, 0, 3, 3]
                },              
                yaxis: [
                  {
                    seriesName: 'Last year Value',
                    show: true,
                    min: 0,
                    max: maxM,
                    labels: {
                      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                      offsetX: -10,
                    }
                  },
                  {
                    seriesName: 'This year Value',
                    show: false,
                    min: 0,
                    max: maxM,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                  {
                    seriesName: 'Last year Transactions',
                    opposite: true,
                    // show: true,
                    min: 0,
                    max: maxM_trans,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                  {
                    seriesName: 'This year Transactions',
                    opposite: true,
                    show: false,
                    min: 0,
                    max: maxM_trans,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                ]
              }}
              series={[
                {	
                  name: 'Last year Value',
                  data: salesM_LY_new,
                  type: 'bar'
                },
                {	
                  name: 'This year Value',
                  data: salesM_new,
                  type: 'bar'
                },
                {	
                  name: 'Last year Transactions',
                  data: salesM_LY_new_trans,
                  type: 'line'
                },
                {	
                  name: 'This year Transactions',
                  data: salesM_new_trans,
                  type: 'line',
                }
              ]}
              width="100%"
            />
          )}
          {this.state.value === "3" && (
            <Box>
              <Chart
              height={350}
              type="line11"
              options={{
                chart: {
                  // type: 'bar',
                  background: 'transparent',
                  stacked: false,
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true | '<img src="/static/icons/reset.png" width="20">',
                      customIcons: []
                    }
                  },
                  export: {
                    csv: {
                      filename: undefined,
                      columnDelimiter: ',',
                      headerCategory: 'category',
                      headerValue: 'value',
                      dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                      }
                    },
                    svg: {
                      filename: undefined,
                    },
                    png: {
                      filename: undefined,
                    }
                  },
                  zoom: {
                    enabled: true,
                    type: 'x',
                    autoScaleYaxis: false,
                    zoomedArea: {
                      fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                      },
                      stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                      }
                    }
                  }
                },
                colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 1,
                  type: 'solid'
                },
                grid: {
                  borderColor: theme.palette.divider,
                  strokeDashArray: 2,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true
                    }
                  }
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    barHeight: '70%',
                    distributed: false,
                    colors: {
                      backgroundBarColors: Array
                    }
                  }
                },
                stroke: {
                  colors: [alpha(theme.palette.primary.main, 0.25), theme.palette.primary.main],
                  show: true,
                  curve: 'smooth', // 'smooth', 'straight', 'stepline'
                  lineCap: 'square', // 'butt', 'square', 'round'
                  width: 2
                },
                theme: {
                  mode: theme.palette.mode
                },
                xaxis: {
                    tickPlacement: 'on',
                    axisBorder: {
                      color: theme.palette.divider,
                      show: true
                    },
                    axisTicks: {
                      color: theme.palette.divider,
                      show: true
                    },
                    labels: {
                      offsetY: 5,
                      style: {
                        colors: theme.palette.text.secondary
                      }
                    }
                },
                markers: {
                  size: [0, 0, 3, 3]
                },              
                yaxis: [
                  {
                    seriesName: 'Last year Value',
                    show: true,
                    min: 0,
                    max: maxWm,
                    labels: {
                      formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                      offsetX: -10,
                    }
                  },
                  {
                    seriesName: 'This year Value',
                    show: false,
                    min: 0,
                    max: maxWm,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                  {
                    seriesName: 'Last year Transactions',
                    opposite: true,
                    // show: true,
                    min: 0,
                    max: maxWm_trans,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                  {
                    seriesName: 'This year Transactions',
                    opposite: true,
                    show: false,
                    min: 0,
                    max: maxWm_trans,
                    labels: {
                    formatter: (value) => (value > 1000 ? `${thousandsSeparator((value/1000).toFixed(1))}K` : `${value.toFixed(2)}`),
                    offsetX: -10,
                    style: {
                      colors: theme.palette.text.secondary
                    }
                  }
                  },
                ]
              }}
              series={[
                {	
                  name: 'Last year Value',
                  data: salesW_LY,
                  type: 'bar'
                },
                {	
                  name: 'This year Value',
                  data: salesW,
                  type: 'bar'
                },
                {	
                  name: 'Last year Transactions',
                  data: salesW_LY_trans,
                  type: 'line'
                },
                {	
                  name: 'This year Transactions',
                  data: salesW_trans,
                  type: 'line'
                }

              ]}
              width="100%"
            />
            </Box>
          )}
          {this.state.value === "4" && (
                  <Chart
                  height={350}
                  type='bar1'
                  series={[
                    {
                      name: 'Daily sales Last Year',
                      data: salesD_LY
                    },
                    {
                    name: 'Daily sales This Year',
                    data: salesD
                    }
                  ]}
                  width={"100%"}
                />
            )}          
        </Box>  
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon fontSize="small">
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
            onClick={this.onDownload} 
            variant="contained" 
            // color="primary"
          >
            Download
          </Button>
        </CardActions>
      </Card>
    );

  }
}

export default OverviewSales_multiple;