import * as React from 'react';
import { Component } from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
import { FaEnvelope } from "react-icons/fa";
import Client from './components/Client';
import BackOfficeConfig from './Config';

import noImage from './images/noImage.png';
import kalopsia_logo from './images/kalopsia_logo.png';

const pages = [];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

async function getProfile (  ) {
  const token = localStorage.getItem('accessToken');

  /* if ( token ) {
    let response = await fetch('https://cartos.kalopsia.pro/api/1.0/user/whoami/', {
      method: 'GET',
      headers: {"Authorization": `Bearer ${token}`}
    })
    .then(data => data.json())
    //.then(data => data.json())

    return response;
  } */
 }




//const ResponsiveAppBar = () => {
class ResponsiveAppBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      anchorEl: null,
      anchorReference: 'anchorEl',
      profile: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        type: null,
        person: {
          address: null,
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          image: noImage
        }
      },
      config: { }
    };


    this.setConfig = this.setConfig.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.setProfileData = this.setProfileData.bind(this);
    this.handleTopClick = this.handleTopClick.bind(this);
    this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    this.handleOpenUserMenu = this.handleOpenUserMenu.bind(this);

    this.profileMenu = React.createRef();
  }

  componentDidMount() {
    //window.addEventListener('load', this.handleLoad);

    this.setConfig ( );
    this.handleProfile ( );


    //this.setProfileData ( );
  }

  componentWillUnmount() { 
    //window.removeEventListener('load', this.handleLoad)  
  }

  setConfig ( ) {
    let configJson = localStorage.getItem ( "config" );
    let config = JSON.parse ( configJson );
    this.setState ( { config: config } );

    window.addEventListener('storageUpdate', () => {
      configJson = localStorage.getItem ( "config" );
      config = JSON.parse ( configJson );
      this.setState ( { config: config } );
    })
  }

  //const [anchorElNav, setAnchorElNav] = React.useState(null);
  //const [anchorElUser, setAnchorElUser] = React.useState(null);

  handleOpenNavMenu ( event ) {
    //setAnchorElNav(event.currentTarget);
  }

  handleOpenUserMenu ( event ) {
    //setAnchorElUser ( event.currentTarget );
    //console.log ( "Open menu !" );
    this.setState({ anchorEl: event.currentTarget });
  }

  handleCloseNavMenu ( ) {
    //setAnchorElNav(null);
  }

  handleCloseUserMenu ( ) {
    //console.log ( "handleCloseUserMenu:" + anchorElUser );
    //setAnchorElUser(null);
    this.setState({ anchorEl: null });
  }

  handleLogout = () => {
    this.state.client.logout ( );
    window.location.href = BackOfficeConfig.BASE_URL;
    /* localStorage.removeItem("apiUser");
    localStorage.removeItem("profile");
    window.location.href = BackOfficeConfig.BACKOFFICE_URL; */
  }

  handleTopClick ( event, value ) {
    //console.log ( "VALUE", event.target.innerText, value );
    switch ( value ) {
      case "Logout":
        this.handleLogout ( );
        break;
      default:
        break;
    }
    this.handleCloseUserMenu();
  }

  setProfileData ( ) {
    let apiUserString = localStorage.getItem ( "apiUser" );
    let apiUser = JSON.parse ( apiUserString );    
    let profile = {
      person: {
        image: noImage,
      }
    };
    if ( apiUser.profile ) {
      //let apiUser.profileJson = JSON.parse ( apiUser.profile );
      if ( apiUser.profile.person ) {
        if ( apiUser.profile.person.image ) profile.person.image = BackOfficeConfig.BASE_URL + apiUser.profile.person.image;
      }
      
      //console.log ( profileJson );
      this.setState ( { profile: profile } );      
    }    
  }

  handleProfile ( ) {
    this.setProfileData ( );
    //e.preventDefault();
    /* const response = getProfile().then ((value) => {        
          localStorage.setItem('profile', "");
          let profileString = JSON.stringify ( value.profile );
          localStorage.setItem('profile', profileString );
          this.setProfileData ( );
        }); */
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let logo = kalopsia_logo;
    let name = "KALOPSIA";

    if ( this.state.config ) {
      let config = this.state.config;
      if ( config.identityImages ) {
        if ( config.identityImages.LOGO ) logo = config.identityImages.LOGO;
      }
      if ( config.identityTexts ) {
        if ( config.identityTexts.NAME ) name = config.identityTexts.NAME;
      }
    }

    return (
      <AppBar position="static" sx={{ bgcolor: "#1e2833" }}>
        <Container maxWidth="false">
          <Toolbar disableGutters variant="dense">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>


            <img src={logo} width="48px"></img>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                marginLeft: 1
              }}
            >
              {name}
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>            
            </Box>
            
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Typography
              variant="h8"
              noWrap
              //component="a"
              //href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'sans-serif',
                fontWeight: 500,
                letterSpacing: '.2rem',
                color: 'inherit',
                textDecoration: 'none',
                align: "right"
              }}
            >
              {this.state.profile.first_name} {this.state.profile.last_name} (HQ Platform)
            </Typography>
            </Box>         

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open profile">
                <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={this.state.profile.first_name} src={this.state.profile.person.image} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                ref={this.profileMenu}
                //anchorEl={anchorElUser}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                //open={Boolean(anchorElUser)}
                open={open}
                onClose={this.handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={(e) => this.handleTopClick(e, setting)}
                  /* sx={{background: "#1e2833" }} */
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
}
}
export default ResponsiveAppBar;
