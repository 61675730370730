import React from 'react';
import { Avatar, Card, CardContent, CardHeader, Stack, SvgIcon, Typography, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import MainCard from './MainCard';

import Client from '../Client';
import Config from '../ClientConfig';

function thousandsSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

class OverviewMonthSales extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      client: new Client ( "none" ),
      sales_total: [],
      filterData: {}
    };

    this.getData = this.getData.bind(this);
    this.doSync = this.doSync.bind(this);

  }

  componentDidMount() {
    this.getData ( );
  }

  getData ( ) {
    let queryData = { "type": 1 }
	let data = this.state.client.getData ( Config.API_URL_REPORTS_FINANCIAL_SALES, queryData );
    if ( data ) {
      this.setState ( { sales_total: data });
    }
    this.setState ( { filterData: queryData } );
  }

  doSync ( ) {
    this.getData ( );
  }

  render() {
    let salesTYV = 0;
    let salesLYV = 0;
    let diff_salesV = 0;
    let diff_posV = false;

    if ( this.state.sales_total ) {
        let sales_total = this.state.sales_total;
        if ( sales_total.sales_TY !== undefined ) salesTYV = parseInt(sales_total.sales_TM);
        if ( sales_total.sales_LY !== undefined ) salesLYV = parseInt(sales_total.sales_TMLY);
        diff_salesV = parseInt((salesTYV / salesLYV -1)*100);
        diff_posV = (diff_salesV >= 0) ? true : false;
    }

    return (
      <Card sx={{ overflow: 'hidden', position: 'relative', borderRadius:"16px", height: '100%' }}>
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography
                color="text.secondary"
                variant="overline"
              >
                SALES THIS MONTH
              </Typography>
              <Typography variant="h4">{thousandsSeparator((salesTYV/1000000).toFixed(1))+"M"}</Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: 'error.main',
                height: 56,
                width: 56
              }}
            >
              <SvgIcon>
              <EuroSymbolIcon />
              </SvgIcon>
            </Avatar>
          </Stack>
          {diff_salesV && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <SvgIcon
                  color={diff_posV ? 'success' : 'error'}
                  fontSize="small"
                >
                  {diff_posV ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </SvgIcon>
                <Typography
                  color={diff_posV ? 'success.main' : 'error.main'}
                  variant="body2"
                >
                  {diff_salesV}%
                </Typography>
              </Stack>
              <Typography
                color="text.secondary"
                variant="caption"
              >
                Since last year
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    );

  }
}

export default OverviewMonthSales;


 {/* <Button
                color="inherit"
                size="small"
                startIcon={(
                <SvgIcon fontSize="small">
                    <AutorenewIcon />
                </SvgIcon>
                )}
                onClick={() => this.doSync()}
            /> */}